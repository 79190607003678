import {
  Box,
  VStack,
  Select,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { categories, subcategories } from "data/categories";

const SalaryRangeGraph = ({ vacancies }: any) => {
  // Calculate salary ranges
  const salaryBuckets = vacancies.reduce((acc: any, { price }: any) => {
    const bucket = Math.floor(price / 10000) * 10000; // Group by every 10,000
    acc[bucket] = (acc[bucket] || 0) + 1;
    return acc;
  }, {});

  // Prepare data for the chart
  const chartData = {
    labels: [
      "",
      ...Object.keys(salaryBuckets).map(
        (bucket) =>
          `$${parseInt(bucket).toLocaleString()} - $${(
            parseInt(bucket) + 9999
          ).toLocaleString()}`
      ),
      "",
    ],
    datasets: [
      {
        label: "Popularity",
        data: [0, ...Object.values(salaryBuckets), 0],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: false, // Hide the y-axis numbers
        },
        title: {
          display: true,
          text: "Popularity",
          font: {
            size: 16,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Salary Range",
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Salary Range Distribution",
        color: "#FFA500",
        font: {
          size: 20,
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

function Graphs({
  vacancies,
  categoryId,
  location,
}: {
  vacancies: any;
  location?: string;
  categoryId?: string;
}) {
  const initialFilterData = {
    vacancyType: "",
    location: location || "",
    category: categoryId || "",
    subcategory: "",
  };
  const [currentOpened, setCurrentOopened] = useState<number | undefined>(0);
  const [filters, setFilters] = useState(initialFilterData);

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    if (filters.category) {
      setFilters((prevFilters) => ({ ...prevFilters, subcategory: "" }));
    }
  }, [filters.category]);

  const filteredVacancies = vacancies.filter((vacancy: any) => {
    return (
      (filters.vacancyType
        ? vacancy.vacancyType === filters.vacancyType
        : true) &&
      (filters.location ? vacancy.location === filters.location : true) &&
      (filters.category
        ? vacancy.category === parseInt(filters.category)
        : true) &&
      (filters.subcategory
        ? vacancy.subcategory === parseInt(filters.subcategory)
        : true)
    );
  });

  return (
    <VStack spacing={4} mt={4} align="stretch" w="100%">
      <Accordion
        index={currentOpened}
        border="1px solid #d3d3d3"
        borderRadius="lg"
      >
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              border="none"
              onClick={() => {
                setCurrentOopened(currentOpened ? 0 : 1);
              }}
            >
              <Box as="span" flex="1" textAlign="left">
                <span style={{ fontWeight: "bold", marginRight: 15 }}>
                  Filters
                </span>
                {((!categoryId && filters.category) ||
                  (!location && filters.location) ||
                  filters.subcategory ||
                  filters.vacancyType) && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFilters(initialFilterData);
                    }}
                  >
                    Clear filters
                  </Button>
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Grid templateColumns="repeat(4, 1fr)" gap={3}>
              <GridItem w="100%">
                <label htmlFor="vacancyType">Vacancy Type</label>
                <Select
                  name="vacancyType"
                  mt={2}
                  placeholder="All"
                  value={filters.vacancyType}
                  onChange={handleFilterChange}
                >
                  <option value="FullTime">Full Time</option>
                  <option value="PartTime">Part Time</option>
                  <option value="Contract">Contract</option>
                </Select>
              </GridItem>
              {!location && (
                <GridItem w="100%">
                  <label htmlFor="location">Location</label>
                  <Select
                    name="location"
                    placeholder="All"
                    value={filters.location}
                    onChange={handleFilterChange}
                    mt={2}
                  >
                    <option value="Remote">Remote</option>
                    <option value="US">US</option>
                    <option value="Europe">Europe</option>
                  </Select>
                </GridItem>
              )}
              {!categoryId && (
                <GridItem w="100%">
                  <label htmlFor="category">Category</label>
                  <Select
                    name="category"
                    placeholder="All"
                    value={filters.category}
                    onChange={handleFilterChange}
                    mt={2}
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </Select>
                </GridItem>
              )}
              {/* <GridItem w="100%">
                <label htmlFor="subcategory">Subcategory</label>
                <Select
                  name="subcategory"
                  placeholder="All"
                  value={filters.subcategory}
                  onChange={handleFilterChange}
                  mt={2}
                  isDisabled={!filters.category}
                >
                  {filters.category &&
                    subcategories[filters.category].map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.title}
                      </option>
                    ))}
                </Select>
              </GridItem> */}
            </Grid>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Box w="100%">
        <SalaryRangeGraph vacancies={filteredVacancies} />
      </Box>
    </VStack>
  );
}

export { Graphs };
