import { PageInnerContent } from "components/page-display/components/PageInnerContent";
import { Graphs } from "./Graphs";
import {
  Alert,
  AlertIcon,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useVacanciesData } from "pages/Vacancy/useVacanciesData";
import { categories } from "data/categories";

const GraphsContent = () => {
  const { vacancies, loading } = useVacanciesData();
  if (loading) {
    return (
      <Box p={4}>
        <Text>Information is loading...</Text>
      </Box>
    );
  }

  return (
    <Box px={4} pb={4}>
      <PageInnerContent title="Salary Ranges Distribution" />
      <Tabs variant="soft-rounded" colorScheme="orange" mt={4}>
        <TabList>
          <Tab fontSize="sm" borderRadius={"lg"}>
            All
          </Tab>
          <Tab fontSize="sm" borderRadius={"lg"}>
            Location: Remote
          </Tab>
          <Tab fontSize="sm" borderRadius={"lg"}>
            Location: US
          </Tab>
          <Tab fontSize="sm" borderRadius={"lg"}>
            Location: Europe
          </Tab>
          <Tab fontSize="sm" borderRadius={"lg"}>
            Vacancies: {categories[0].title}
          </Tab>
          <Tab fontSize="sm" borderRadius={"lg"}>
            Vacancies: {categories[1].title}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Graphs vacancies={vacancies} location="" categoryId={undefined} />
          </TabPanel>
          <TabPanel>
            <Graphs
              vacancies={vacancies}
              location="Remote"
              categoryId={undefined}
            />
          </TabPanel>
          <TabPanel>
            <Graphs
              vacancies={vacancies}
              location="US"
              categoryId={undefined}
            />
          </TabPanel>
          <TabPanel>
            <Graphs
              vacancies={vacancies}
              location="Europe"
              categoryId={undefined}
            />
          </TabPanel>
          <TabPanel>
            <Graphs vacancies={vacancies} location="" categoryId={"0"} />
          </TabPanel>
          <TabPanel>
            <Graphs vacancies={vacancies} location="" categoryId={"1"} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default GraphsContent;
