import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MenuItem } from "components/page-display/components/Sidenav";
import { VacancyCreatorModalContent } from "./VacancyCreatorModalContent";
import QueryVacancy from "./VacancyQueryModalContent";

const SearchIcon = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
function SearchVacancyModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        item={{ content: "Find", path: "", icon: SearchIcon }}
        onClick={() => onOpen()}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Find a Vacancy</ModalHeader>
          <ModalCloseButton />
          <QueryVacancy />
        </ModalContent>
      </Modal>
    </>
  );
}

export { SearchVacancyModal };
