import {
  ApiProvider as GearApiProvider,
  AlertProvider as GearAlertProvider,
  AccountProvider,
  ProviderProps,
} from "@gear-js/react-hooks";
import { Alert, alertStyles } from "@gear-js/ui";
import { ComponentType } from "react";
import { BrowserRouter } from "react-router-dom";
import { ADDRESS } from "consts";

function ApiProvider({ children }: ProviderProps) {
  return (
    <GearApiProvider providerAddress={ADDRESS.NODE}>{children}</GearApiProvider>
  ) as any;
}

function AlertProvider({ children }: ProviderProps) {
  return (
    <GearAlertProvider template={Alert} containerClassName={alertStyles.root}>
      {children}
    </GearAlertProvider>
  ) as any;
}

const providers = [BrowserRouter, AlertProvider, ApiProvider, AccountProvider];

function withProviders(Component: any) {
  const component = (<Component />) as unknown as any;
  return () =>
    providers.reduceRight(
      (children, Provider) => <Provider>{children}</Provider>,
      component
    );
}

export { withProviders };
